import React from "react"
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import '../css/qualidade.css'



class Quality extends React.Component {

    render() {
        return (
            <>
                <Metatags
                    title="Maxipack"
                    description="Boas Práticas de Fabricação, Sistema de auditoria, QVT - Qualidade de vida no trabalho"
                    url="https://www.maxipack.com.br/qualidade/"
                    image="https://www.maxipack.com.br/maxipack.png"
                    keywords="Boas Práticas de Fabricação, Sistema de auditoria, QVT, Qualidade de vida no trabalho"
                />
                <Navbar />
                <div className="space-50" style={{ height: "50px" }} />
                <Container>
                    <Row>
                        <Col>
                            <img
                                className="d-block w-100"
                                src={require("../images/Testeira_Qualidade_3.jpg")}
                                alt="Quem somos"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="homeText">
                                <h4>Qualidade</h4>
                                <p>A Maxi Pack possui todos os registros necessários para sua operação, além de BPF, Treinamentos, auditorias internas e externas frequentes, que garantem nosso padrão de qualidade.</p>
                                <p>É fornecedora de empresas nacionais e internacionais que têm um rígido controle de aprovação dos processos produtivos executados dentro da Maxi Pack.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="green-dash">&nbsp;</div>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="vertical-green-dash" />
                            <div className="quality-block">
                                <h4 style={{ marginBottom: 0 }}>BPF - Boas Práticas de Fabricação</h4>
                                <p>Abrange um conjunto de medidas que devem ser adotadas pelas indústrias de alimentos. Tem como objetivo garantir a segurança dos produtos manuseados pela Maxi Pack.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="vertical-green-dash" />
                            <div className="quality-block">
                                <h4 style={{ marginBottom: 0 }}>Sistema de auditoria</h4>
                                <p>A Maxi Pack tem um sistema interno de auditoria, além de ser auditada periodicamente pela SGS e por seus clientes multinacionais extremamente exigentes.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="vertical-green-dash" />
                            <div className="quality-block">
                                <h4 style={{ marginBottom: 0 }}>QVT - Qualidade de vida no trabalho</h4>
                                <p>Satisfação dos colaboradores, que leva a uma melhora na qualidade do trabalho, gerando melhores produtos e serviços, levando à fidelização dos clientes e criando melhores resultados para todos envolvidos.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col>
                            <img
                                className="d-block w-100"
                                src={require("../images/Testeira_Qualidade_2.jpg")}
                                alt="Quem somos"
                            />
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                </Container>
                <Footer />

            </>
        )
    }
}

export default Quality